<button *ngIf="!isMenuFixed" id="menu-button" pButton pRipple (click)="isVisible = true" class="p-button-rounded">
  <fa-icon [icon]="['fas', 'bars']"></fa-icon>
</button>
<p-sidebar
  #sidebar
  [(visible)]="isMenuFixed || isVisible"
  position="right"
  [dismissible]="!isMenuFixed"
  [showCloseIcon]="!isMenuFixed"
  [style]="{width: '250px'}"
  [modal]="false"
>
  <ng-template pTemplate="header">
    <div class="flex flex-row align-items-center">
      <svg class="logo" width="164" height="142" viewBox="0 0 164 142" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="82.0002" cy="59.9999" r="74.7912" fill="url(#paint0_radial_1675_8454)" fill-opacity="0.04" />
        <circle cx="82" cy="59.9999" r="52.2637" fill="url(#paint1_radial_1675_8454)" fill-opacity="0.1" />
        <circle cx="82" cy="60" r="28.8352" fill="url(#paint2_radial_1675_8454)" fill-opacity="0.3" />
        <circle cx="82" cy="60" r="7.88461" fill="#FF8A00" />
        <defs>
          <radialGradient
            id="paint0_radial_1675_8454"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(82.0002 59.9999) rotate(90) scale(74.7912)"
          >
            <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
            <stop offset="1" stop-color="#FF8A00" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1675_8454"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(82 59.9999) rotate(90) scale(52.2637)"
          >
            <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
            <stop offset="1" stop-color="#FF8A00" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_1675_8454"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(82 60) rotate(90) scale(28.8352)"
          >
            <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
            <stop offset="1" stop-color="#FF8A00" />
          </radialGradient>
        </defs>
      </svg>
      <h2>KatHelferPRO</h2>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ul class="menu-list">
      <li>
        <a routerLink="/map">
          <fa-icon class="list-item-icon" [icon]="['fas', 'map']"></fa-icon>
          <span i18n="@@main-menu.map-view">Map View</span>
        </a>
      </li>
      <li *ngIf="checkHasAllRoles(readDataRoles)">
        <a routerLink="/analytics">
          <fa-icon class="list-item-icon" [icon]="['fas', 'chart-line']"></fa-icon>
          <span i18n="@@main-menu.analytics">Analytics</span>
        </a>
      </li>
      <li *ngIf="checkHasAllRoles(readDataRoles)">
        <a routerLink="/details">
          <fa-icon class="list-item-icon" [icon]="['fas', 'clipboard-list']"></fa-icon>
          <span i18n="@@main-menu.detailed-view">Details</span>
        </a>
      </li>
      <li *ngIf="checkHasAnyRole(controlCenterRoles)">
        <a routerLink="/control">
          <fa-icon class="list-item-icon" [icon]="['fas', 'gamepad']"></fa-icon>
          <span i18n="@@main-menu.control-center">Control Center</span>
        </a>
      </li>

      <li>
        <app-lang-switcher></app-lang-switcher>
      </li>

      <li (click)="onLogout()">
        <a>
          <fa-icon class="list-item-icon" [icon]="['fas', 'door-open']"></fa-icon>
          <span i18n="@@main-menu.logout">Logout</span>
        </a>
      </li>
    </ul>
  </ng-template>
</p-sidebar>
