import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NotFoundPageComponent} from 'viz-common';
import {AuthGuard} from '@app/auth.guard';
import {CONTROL_CENTER_ROLES, READ_DATA_ROLES} from '@app/roles';

const routes: Routes = [
  {path: '', redirectTo: 'map', pathMatch: 'full'},
  {
    path: 'map',
    loadChildren: () => import('./map-view/map-view.module').then((m) => m.MapViewModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard],
    data: {roles: READ_DATA_ROLES, every: true},
  },
  {
    path: 'details',
    loadChildren: () => import('./detailed-view/detailed-view.module').then((m) => m.DetailedViewModule),
    canActivate: [AuthGuard],
    data: {roles: READ_DATA_ROLES, every: true},
  },
  {
    path: 'control',
    loadChildren: () => import('./control-center/control-center.module').then((m) => m.ControlCenterModule),
    canActivate: [AuthGuard],
    data: {roles: CONTROL_CENTER_ROLES},
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
